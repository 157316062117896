import { triggerEvent } from '../../utils/dom'
import EventBus from '../../core/EventBus'

const defaults = {
    ajax: false,
    data: null,
    onStart: () => {},
    onComplete: () => {}
}

const requests = {}

export function submitForm(node, config = {}) {
    if (!node) {
        return
    }

    const options = {
        ...defaults,
        ...config
    }

    const form = node.closest('form')
    const ajax = 'ajax' in config ? options.ajax : form.classList.contains('ajax')
    const id = form.id

    if (ajax) {

        if (id && id in requests) {
            requests[id].abort()
            delete requests[id]
        }

        const request = $.nette.ajax({
            url: form.action,
            method: form.method || 'GET',
            dataType: 'json',
            data: options.data ? options.data : $(form).serialize(),
            beforeSend: () => {
                if (options.onStart) {
                    options.onStart()
                }
                form.classList.add('is-loading')
                EventBus.emit('nette:ajax:submit', {
                    id: form.id
                })
            },
            complete: () => {
                if (form) {
                    form.classList.remove('is-loading')
                }
                if (options.onComplete) {
                    options.onComplete()
                }
            }
        })

        if (id) {
            requests[id] = request
        }
    } else {
        form.submit()
    }
}

function watch(context) {
    let timer = null

    context.addEventListener('nette:autosubmit', event => {
        if (event.defaultPrevented) {
            return
        }

        submitForm(event.target)
    })

    context.addEventListener('change', event => {
        const form = event.target.closest('form')
        const field = event.target.closest('select[data-autosubmit], input[data-autosubmit]')

        if (field || form?.dataset?.autosubmit != null) {
            triggerEvent(form, 'nette:autosubmit')
        }
    })

    context.addEventListener('input', event => {
        const form = event.target.closest('form')
        const field = event.target.closest('input[data-autosubmit], textarea[data-autosubmit]')

        if (field || form?.dataset?.autosubmit != null) {
            clearTimeout(timer)
            timer = setTimeout(() => triggerEvent(form, 'nette:autosubmit'), 200)
        }
    })
}

watch(document)