import { toggleClass } from '../utils/dom'
import Emitter from '../core/Emitter'

export const STATUS = {
    TOUCH: 'is-touch-pointer',
    MOUSE: 'is-mouse-pointer'
};

export class TouchMouseInputResolver extends Emitter {

    TOUCH = 1
    MOUSE = 2

    constructor() {
        super()
        this.body = document.documentElement;

        this.attach();

        this.isTouch = false;
        this.isMouse = false;
        this.current = false;

        this.isTouched = false;
        this.timer = null;
    }

    attach() {
        this.registerTouch();
        this.registerMouse();
    }

    detach() {
        this.unregisterTouch();
        this.unregisterMouse();
    }

    registerTouch() {
        this.body.addEventListener('touchstart', this.handleTouchStart, {
            passive: true
        });
        this.body.addEventListener('touchcancel', this.handleTouchEnd, {
            passive: true
        });
        this.body.addEventListener('touchend', this.handleTouchEnd, {
            passive: true
        });
    }

    unregisterTouch() {
        this.body.removeEventListener('touchstart', this.handleTouchStart, {
            passive: true
        });
        this.body.removeEventListener('touchcancel', this.handleTouchEnd, {
            passive: true
        });
        this.body.removeEventListener('touchend', this.handleTouchEnd, {
            passive: true
        });

        this.isTouched = false;
    }

    registerMouse() {
        this.body.addEventListener('mousemove', this.handleMouseMove);
    }

    unregisterMouse() {
        this.body.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleTouchStart = () => {
        clearTimeout(this.timer);

        this.isTouched = true;

        if (!this.isTouch) {
            this.isMouse = false;
            this.isTouch = true;
            this.check();
        }
    }

    handleTouchEnd = event =>  {
        // this.log('touchend');
        this.timer = setTimeout(() => {
            this.isTouched = false;
        }, 100);
    }

    handleMouseMove = event => {

        if (this.isTouched || this.isMouse) { // prevent mousemove after touchstart
            return;
        }

        // this.log('mousemove');

        if (!this.isMouse) {
            this.isMouse = true;
            this.isTouch = false;
            this.check();
        }
    }

    log(value) {
        if (!this.logElement) {
            this.logElement = document.createElement('div');
            this.logElement.style.background = 'white';
            this.logElement.style.display = 'block';
            this.logElement.style.position = 'fixed';
            this.logElement.style.right = '20px';
            this.logElement.style.top = '20px';
            this.logElement.style.zIndex = '999';
            this.logElement.style.border = '1px solid black';
            this.logElement.style.width = '300px';
            this.logElement.style.padding = '20px';
            document.body.appendChild(this.logElement);
        }

        const div = document.createElement('div');
        div.innerText = Date.now() + ': ' + value;
        this.logElement.appendChild(div);
    }

    check() {
        if (this.isTouch) {
            this.current = this.TOUCH
        } else {
            this.current = this.MOUSE
        }
        toggleClass(this.body, STATUS.TOUCH, this.isTouch)
        toggleClass(this.body, STATUS.MOUSE, this.isMouse)
        this.emit('change', {
            type: this.current
        })
    }

}

const InputResolver = new TouchMouseInputResolver()

export default InputResolver
