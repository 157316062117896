// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'
import 'proxy-polyfill/proxy.min.js'

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

try {
    Array.from
    Object.assign
    // Object.findIndex
} catch (e) {
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'


import { USE_NATIVE_SCROLL } from './core/config'
import ScrollService from './services/Scroll'

if (USE_NATIVE_SCROLL) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

setTimeout(() => {
    ScrollService.resize()
}, 200)

import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'



import Swup from 'swup'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'

EventBus.on('content:change', () => {
    ScrollService.resize()
})

if (true) {
    let skipPopState = false
    EventBus.on('swup:custom:block', () => skipPopState = true)

    const scrollStack = []

    function pushScrollPosition() {
        const location = window.location.href
        const offset = ScrollService.getScroll().y

        scrollStack.push({
            location,
            offset
        })

        if (scrollStack > 10) {
            scrollStack.shift()
        }
    }

    function popScrollPosition() {
        return scrollStack.pop()
    }

    let currentScroll = null

    const swup = new Swup({
        linkSelector: [
            'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="/"]:not([data-no-swup]):not([target="_blank"]):not(.ajax)',
            'a[href^="#"]:not([data-no-swup]):not([data-component]):not(.ajax):not([href="#todo])'
        ].join(', '),
        cache: false,
        animationSelector: '.Overlay:not(.is-disabled)',
        plugins: [new SwupBodyClassPlugin()],
        containers: ['#page-content', '#modals', '#language'],
        skipPopStateHandling: event => {
            if (skipPopState) {
                skipPopState = false
                return true
            }

            if (event?.state?.source === 'swup') {
                return false
            }

            return true
        }
    })

    swup.on('animationOutStart', event => {
        EventBus.emit('swup:animationoutstart', { event })
    })

    swup.on('clickLink', event => {
        pushScrollPosition()
        EventBus.emit('swup:clicklink', { event })
    })

    swup.on('willReplaceContent', event => {
        const elements = queryAll('[data-swup]')
        queryAll('[data-swup]').forEach(element => removeComponents(element))
        EventBus.emit('swup:beforecontentchange')
    })

    swup.on('contentReplaced', event => {
        Nette.reinit()
        const id = window.location.hash.replace('#', '')
        const anchor = document.getElementById(id)

        queryAll('[data-swup]').forEach(element => loadComponents(element))

        if (currentScroll) {
            setTimeout(() => {
                ScrollService.setPosition(0, currentScroll.offset)
                currentScroll = null
            }, 0)
        } else if (anchor) {
            const offset = anchor.getBoundingClientRect().top + ScrollService.getScroll().y
            setTimeout(() => {
                ScrollService.setPosition(0, offset)
            }, 0)
        } else {
            ScrollService.setPosition(0, 0)
        }

        if ($.nette) {
            $.nette.load()
        }

        EventBus.emit('swup:aftercontentchange')
    })

    swup.on('transitionEnd', event => {
        EventBus.emit('swup:transitionend')
    })

    swup.on('popState', event => {
        const settings = popScrollPosition()
        if (settings?.location == window.location.href) {
            currentScroll = settings
        }
    })

    window.swup = swup
}

EventBus.on('nette:ajax:complete', () => {
    ScrollService.resize()
})
